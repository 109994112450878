import React from "react";
import { Helmet } from "react-helmet";
import Phone from "../../components/Shared/Phone"
const classes = require('./WineDinner.module.scss');

const wineDinner = () => {
  return (
    <div className={classes.WineDinner}>
      <Helmet>
        <title>Waterfront Wine Dinners at Mile Marker 158 Dockside</title>
        <meta name="description" content="Check out the next wine pairing at Mile Marker 158 Dockside, located at The Wharf in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.medDef}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}>
              <div className={classes.inner}>
                <h1>February Wine Dinner</h1>
                <h2>
                  February 6 <br className={classes.br1}/>
                  <span className={classes.sp1}>&nbsp;|&nbsp;</span> 6pm <span className={classes.sp2}>&nbsp;|&nbsp;</span> $55 Per Guest
                </h2>
                <h3>Hosted by Rush Wines</h3>
                <h4>For Reservations: <Phone where="wineDinner" /></h4>
              </div>
            </div>
          </div>

          <div className={[classes.inside, classes.stip].join(' ')}>
            <div className={classes.menuRow}>
              <div className={classes.menuBoxFull}>
                <h5>First Course</h5>
                <h4>BRISKET & BARLEY SOUP</h4>
                <p>Smoked Brisket, Fresh Vegetables, Barley</p>
                <p className={classes.pair}>Paired With</p>
                <h4>MERCATO CARNE ROSSO &middot; ITALY</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Second Course </h5>
                <h4>CRAWFISH EGGROLLS</h4>
                <p>Cabbage, Onion, Carrots, Cilantro, House Made Sweet & Sour Sauce</p>
                <p className={classes.pair}>Paired With</p>
                <h4>RAMSAY PINOT NOIR &middot; CA</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Third Course</h5>
                <h4>GRILLED HALIBUT</h4>
                <p>Asiago Gnocchi, Lima Bean and Corn Succotash, Strawberry Buerre Blanc</p>
                <p className={classes.pair}>Paired With</p>
                <h4>MILO CHARDONNAY &middot; FRANCE</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Fourth Course</h5>
                <h4>APPLE STRUDEL</h4>
                <p>Flaky Pastry, Cinnamon & Rum Glaze</p>
                <p className={classes.pair}>Paired With</p>
                <h4>LULU BRUT ROSE &middot; WA</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default wineDinner;